const ARKON_STORIES_AJAX_CONTROLLER_URL = location.origin + '/module/arkonstories/ajax';

export default class LikeAction {
    init() {
        this.initLikeButtons();
        this.initLikesEvents();
    }

    initLikeButtons() {
        const likeButtons = document.querySelectorAll('.js-story-like');

        for(const button of likeButtons) {
            button.addEventListener('click', () => {
                this.onLikeButtonClick(button);
            });
        }
    }

    onLikeButtonClick(button) {
        if(!button || !button.dataset || !button.dataset.story) {
            return
        }

        this.likeButtonPost(button.dataset.story);
    }

    likeButtonPost(id_story) {
        $.ajax({
            type: "POST",
            url: ARKON_STORIES_AJAX_CONTROLLER_URL,

            data: {
                id_story: id_story,
                action: 'like'
            },

            success: (data) => {
                this.likeButtonPostSuccess(data);
            },
        });
    }

    likeButtonPostSuccess(data) {
        data = JSON.parse(data);
        
        if(!data.success) {
            return;
        }

        document.dispatchEvent(
            new CustomEvent('story-liked', {detail: {
                story: data.id_story, 
                likes: data.likes
            }})
        );
    }

    initLikesEvents() {
        document.addEventListener('story-liked', (e) => {
            if(!e.detail || !e.detail.story || !e.detail.likes) {
                return;
            }

            this.onStoryLike(e.detail.story, e.detail.likes);
        });
    }

    onStoryLike(id_story, likes) {
        const likedStory = document.getElementById('arkon-story-' + id_story);

        if(!likedStory) {
            return;
        }
            
        likedStory.classList.add('arkon-story--liked');

        const likesCount = likedStory.querySelector('.js-like-count');

        if(!likesCount) {
            return;
        }

        likesCount.innerHTML = likes;
    }
}